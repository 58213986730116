const fields = [
    'uniqueHash',
    'companyName',
    'city',
    'lat',
    'lng',
    'active',
    'creditorNumber',
    'hasWalkIn',
    'amountOfLessons',
    'creditsUsed',
    'totalBookings',
    'reimbursementValueOfCredits',
    'users',
    'lessonTypes',
    'facilities',
    'createdAt',
    'partnerConnectors',
];
let partnerPropertiesConstant = { 'properties[0]': 'id' };
fields.forEach((field, key) => {
    partnerPropertiesConstant['properties[' + (key + 1) + ']'] = field;
});
export default partnerPropertiesConstant;
